import { atom } from 'recoil';
const BackgroundAtom = atom({
  key: "BackgroundAtom",
  default: false,
})
const EstateButtonAtom = atom({
  key: "EstateButtonAtom",
  default: false,
})
const FirstViewAtom = atom({
  key: "FirstViewAtom",
  default: false,
})
const FirstVisitedAtom = atom({
  key: "FirstVisitedAtom",
  default: true,
})
export {
  BackgroundAtom,
  EstateButtonAtom,
  FirstViewAtom,
  FirstVisitedAtom
};