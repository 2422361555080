import { useCallback } from 'react'
import { useSetRecoilState, useRecoilValue } from "recoil"
import { BackgroundAtom } from "../atom/GlobalAtom"

// 背景のstateを管理
type Background = State & {
  activate: () => void;
  deactivate: () => void;
};

type State = {
  isActive: boolean;
};

const useBackground = (): Background => {

  const isActive = useRecoilValue(BackgroundAtom);
  const setIsActive = useSetRecoilState(BackgroundAtom);

  const activate = useCallback(function () {
    setTimeout(() => {
      setIsActive(true);
    }, 300);
  }, []);

  const deactivate = useCallback(() => setIsActive(false), []);

  return {
    isActive,
    activate,
    deactivate,
  };
}
export default useBackground;