import { useEffect, } from "react";
import { Routes, Route, useLocation } from 'react-router-dom';
import Home from '../components/pages/home/Index';
import Contact from '../components/pages/contact/Index';
import ContactConfirm from '../components/pages/contact/Confirm';
import ContactSubmitted from '../components/pages/contact/Submitted';
import WorksShow from '../components/pages/works/Show';
import About from '../components/pages/about/Index';
import VirtualEstate from '../components/pages/virtualEstate/Index';
import Terms from '../components/pages/terms/Index';
import NotFound from '../components/pages/errors/NotFound';
import { AnimatePresence } from "framer-motion";
import useCurrentPage from 'hooks/useCurrentPage'
import { deactivateHoverCursor } from "modules/mouseStalker"

type Props = {
}
export default function Router(props: Props) {
  const location = useLocation();

  const currentPage = useCurrentPage();
  useEffect(() => {
    deactivateHoverCursor();
  }, [currentPage]);
  return (
    <>
      <AnimatePresence mode="wait" initial={true} onExitComplete={() => document.documentElement.scrollTop = 0}>
        <Routes location={location} key={location.pathname}>
          <Route path="/" element={<Home />} />
          <Route path="/works/:id" element={<WorksShow />} />
          <Route path="/about" element={<About />} />
          <Route path="/virtual_estate" element={<VirtualEstate />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/contact/confirm" element={<ContactConfirm />} />
          <Route path="/contact/submitted" element={<ContactSubmitted />} />
          <Route path="/terms" element={<Terms />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </AnimatePresence>
    </>
  );
};