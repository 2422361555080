import { useCallback } from 'react'
import { useSetRecoilState, useRecoilValue } from "recoil"
import { EstateButtonAtom } from "../atom/GlobalAtom"

// 背景のstateを管理
type Background = State & {
  activate: () => void;
  deactivate: () => void;
};

type State = {
  isActive: boolean;
};

const useEstateButton = (): Background => {

  const isActive = useRecoilValue(EstateButtonAtom);
  const setIsActive = useSetRecoilState(EstateButtonAtom);

  const activate = useCallback(function () {
      setIsActive(true);
  }, []);

  const deactivate = useCallback(() => setIsActive(false), []);

  return {
    isActive,
    activate,
    deactivate,
  };
}
export default useEstateButton;