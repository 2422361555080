import { useEffect, } from "react";
import { qsAll, ISOvserve, removeClassAll, addClassAll, } from "modules/modules"
import logo_wh from 'assets/img/common/logo_bk.svg'
import blob from 'assets/img/home/blob.gif'

type Props = {
  modifire?: string
}
export default function Blob(props: Props) {


  return (
    <svg viewBox="0 0 100 100" className={`blob_wrapper ${props.modifire ? props.modifire : ""}`}>
      <path fill="#f0f0f0" className="blob" d="M78,18.4C66.1,8.7,42.2,5.6,27.2,14.7C8,26.5,3.3,55.9,16.2,73.8C31.7,95.3,67.4,97,82,77.3
	C94,61,94.7,31.9,78,18.4z" />
    </svg>
  );
}
