import { motion } from "framer-motion"
import { useNavigate, useLocation } from "react-router-dom"
import { Helmet } from "react-helmet-async"
import { useEffect, useState } from 'react';
import { qs, ISOvserver, scrollToSelector, } from 'modules/modules'
import { validateName, validateOrganization, validateEmail, validateCategory, validateDetails, validateAgreement } from '../../../modules/validate'
import PrimaryHeading from 'components/headings/PrimaryHeading'
import { activateHoverCursor, deactivateHoverCursor } from "modules/mouseStalker"
import React from "react";
import PrimaryButton from "components/ui/PrimaryButton"
import useBackground from "hooks/useBackground"
import { SITE_TITLE, SITE_URL_BASE } from 'config/Constants';


// ダミーデータ入力用
import InputDummyData from './InputDummyData'


import { Errors, Values, Categories } from './Types'



type Props = {
}


const Index = (props: Props) => {

  /*=======================================
    初期化
  =======================================*/

  const navigate = useNavigate(); // ページ遷移用
  const location = useLocation();
  const background = useBackground();


  const [errorMessages, setErrorMessages] = useState(initErrorMessage()); // エラー項目一覧格納用
  const [inputValues, setInputValues] = useState(initValues()); // 値格納用



  // アニメーション用
  useEffect(() => {
    let observer: IntersectionObserver;
    observer = ISOvserver();
    background.activate();
    return () => {
      observer?.disconnect();
    };
  }, []);

  // ラジオボタン初期化用
  const radioCategories: Array<Categories> = [
    "お仕事",
    "取材",
    "コラボ"
  ]

  // エラーメッセージオブジェクトの初期化
  function initErrorMessage(): Errors {
    return {
      errorItems: [] as string[],
      name: "",
      organization: "",
      email: "",
      category: "",
      details: "",
      agreement: "",
    }
  }

  // フォームの値オブジェクトの初期化
  function initValues(): Values {
    if (location.state?.values) {
      return location.state.values
    }
    return {
      name: "",
      organization: "",
      email: "",
      category: "",
      details: "",
      agreement: false,
    }
  }

  /*=======================================
    バリデーション
  =======================================*/


  // 全てのバリデーションを実行
  function isValidValue(values: Values): boolean {
    const errors: Errors = initErrorMessage();
    validateName(values.name, errors);
    validateOrganization(values.organization, errors);
    validateEmail(values.email, errors);
    validateCategory(values.category, radioCategories, errors);
    validateDetails(values.details, errors);
    validateAgreement(values.agreement, errors);
    setErrorMessages(errors);
    return !Boolean(errors.errorItems.length)
  }

  function handleSubmit(e: any) {

    e.preventDefault();

    const values: Values = {
      organization: qs("#organization").value,
      name: qs("#name").value,
      email: qs("#email").value,
      category: qs(".category_radio:checked").value,
      details: qs("#details").value,
      agreement: qs("#agreement").checked,
    };

    const isValid = isValidValue(values)

    if (isValid) {
      navigate('/contact/confirm', { state: { values: values } })
    } else {
      scrollToSelector("#js_err_existence");
    }

  };


  return (
    <>
      <Helmet>
        <title>{`Contact｜${SITE_TITLE}`}</title>
        <meta name="description" content={`お問い合わせ｜${SITE_TITLE}`} />
        <meta property="og:url" content={SITE_URL_BASE + "/contact"} />
        <meta property="og:title" content={`Contact｜${SITE_TITLE}`} />
      </Helmet>

      <motion.div
        initial={{ opacity: 0 }} // このページに遷移した直後
        animate={{ opacity: 1 }} // 最終的な状態
        exit={{ opacity: 0 }} // 離れる際にどうなるか
        transition={{ duration: 0.3 }} // 切り替えに要する時間
      >

        <main id="p__contact" className="l__main_content">

          <PrimaryHeading letter="CONTACT" />

          {/* ダミーデータ入力用 */}
          {/* <InputDummyData /> */}

          <div className="contact_inner c__js_fade">

            <form name="contact" method="post">

              <p id="js_err_existence" className={"c__error_message all_error_messages" + (errorMessages.errorItems.length ? " active" : "")}>
                入力内容をご確認ください。<br />
                {errorMessages.errorItems.map((error: any, index: number) => {
                  return (
                    <span key={index} className="u__error_list">{"・" + error}</span>
                  )
                })}
              </p>

              <div className="input_wrapper">
                <label className="c__contact_label required">お名前／活動名</label>
                <input type="text" id="name" name="name" className="c__contact_textbox" maxLength={60} placeholder="例：ヲタきち" defaultValue={inputValues.name} onMouseOver={activateHoverCursor} onMouseOut={deactivateHoverCursor} />
              </div>
              <p id="nameErrorMessage" className={"c__error_message" + (errorMessages.name ? " active" : "")}>{errorMessages.name}</p>

              <div className="input_wrapper">
                <label className="c__contact_label">会社名／団体名</label>
                <input type="text" id="organization" name="organization" className="c__contact_textbox" maxLength={60} placeholder="例）合同会社nin" defaultValue={inputValues.organization} onMouseOver={activateHoverCursor} onMouseOut={deactivateHoverCursor} />
              </div>
              <p id="organizationErrorMessage" className={"c__error_message" + (errorMessages.organization ? " active" : "")}>{errorMessages.organization}</p>

              <div className="input_wrapper">
                <label className="c__contact_label required">メールアドレス</label>
                <input type="email" id="email" name="email" className="c__contact_textbox" maxLength={256} placeholder="例）xxx@xxx.com" data-conv-half-alphanumeric="true" defaultValue={inputValues.email} onMouseOver={activateHoverCursor} onMouseOut={deactivateHoverCursor} />
              </div>
              <p id="emailErrorMessage" className={"c__error_message" + (errorMessages.email ? " active" : "")}>{errorMessages.email}</p>

              <div className="input_wrapper wide_margin">
                <label className="c__contact_label required">お問い合わせ種別</label>

                <div className="radio_buttons_wrapper">
                  {radioCategories.map((category: any, index: number) => {
                    return (
                      <React.Fragment key={index}>
                        <input type="radio" id={"c" + index} name="category" value={category} className="c__contact_radio category_radio" defaultChecked={index === 0 || inputValues.category === category} />
                        <label htmlFor={"c" + index} onMouseOver={activateHoverCursor} onMouseOut={deactivateHoverCursor}>{category}</label>
                      </React.Fragment>
                    )
                  })}
                </div>
              </div>
              <p id="categoryErrorMessage" className={"c__error_message" + (errorMessages.category ? " active" : "")}>{errorMessages.category}</p>

              <div className="input_wrapper">
                <label className="c__contact_label required">お問い合わせ内容</label>
                <textarea id="details" name="details" className="c__contact_textarea" maxLength={1000} placeholder="お問い合わせ内容をご記入ください" defaultValue={inputValues.details} onMouseOver={activateHoverCursor} onMouseOut={deactivateHoverCursor} />
              </div>
              <p id="detailsErrorMessage" className={"c__error_message" + (errorMessages.details ? " active" : "")}>{errorMessages.details}</p>

              <div className="input_wrapper agreement_wrapper wide_margin">
                <label className="c__contact_label required">プライバシーポリシー</label>
                <div className="radio_buttons_wrapper">
                  <input type="checkbox" id="agreement" name="agreement" value="agreed" className="c__contact_checkbox" defaultChecked={inputValues.agreement} />
                  <label htmlFor="agreement" onMouseOver={activateHoverCursor} onMouseOut={deactivateHoverCursor}><a href="/terms" target="_blank" rel="noopener noreferrer">内容を確認</a>し、同意する</label>
                </div>
              </div>
              <p id="agreementErrorMessage" className={"c__error_message" + (errorMessages.agreement ? " active" : "")}>{errorMessages.agreement}</p>

              <PrimaryButton mode="button" type="submit" id="submit" onClick={(e: any) => handleSubmit(e)}>確認画面へ</PrimaryButton >

            </form>
          </div>

        </main>


      </motion.div>
    </>
  );
}
export default Index;