import { useEffect } from 'react';
import { qs, removeClass, } from "modules/modules"

import { isMobile } from 'react-device-detect';
const MouseStalker = () => {

  let stalker: any;
  useEffect(() => {
    if (!isMobile) {
      stalker = qs('#mouse_stalker');
      document.addEventListener('mousemove', stalk);
      setTimeout(() => {
        removeClass(stalker, "disabled");
      }, 3000);
    }
    return () => {
      document.removeEventListener('mousemove', stalk);
    }
  }, []);

  function stalk(e: any) {
    stalker.style.transform = `translate(${e.clientX}px, ${e.clientY}px)`;
  }

  return (
    <>

    </>
  );
}
export default MouseStalker;