import { useEffect } from 'react';
import { motion } from "framer-motion"
import { Helmet } from "react-helmet-async"
import PrimaryButton from "components/ui/PrimaryButton"
import useBackground from "hooks/useBackground"
import { SITE_TITLE, SITE_URL_BASE } from 'config/Constants';

type Props = {
}

const NotFound = (props: Props) => {
  const background = useBackground();

  useEffect(() => {
    background.activate()
  }, []);

  return (
    <>
      <motion.div
        initial={{ opacity: 0 }} // このページに遷移した直後
        animate={{ opacity: 1 }} // 最終的な状態
        exit={{ opacity: 0 }} // 離れる際にどうなるか
        transition={{ duration: 0.3 }} // 切り替えに要する時間
      >
        <main id="p__notfound" className="">
          <h2 className="c__head_secondary">404 <span className="u__ib">NOT FOUND</span></h2>
          <p className="c__common_text">
            お探しのページが見つかりませんでした。
          </p>
          <PrimaryButton mode="link" path="/">TOPへ戻る</PrimaryButton >
        </main>
      </motion.div>
    </>
  );
}
export default NotFound;