
import './assets/scss/App.scss';
import { setSvh, setStaticSvh } from './modules/modules'
import { Helmet } from "react-helmet-async"
import { useEffect } from 'react';
import { RecoilRoot } from 'recoil';
import { BrowserRouter } from 'react-router-dom';
import Router from 'config/Router';
import Header from 'components/layouts/header/Header';
import Footer from 'components/layouts/footer/Footer';
import MouseStalker from 'components/ui/MouseStalker';
import { SITE_TITLE, SITE_DESCRIPTION_BASE, SITE_URL_BASE } from 'config/Constants';

export default function App() {

  useEffect(() => {
    setSvh();
    setStaticSvh();
    window.addEventListener('resize', setSvh);
    return () => {
      window.removeEventListener('resize', setSvh);
    }
  }, []);

  return (
    <>
      <Helmet>
        <title>{SITE_TITLE}</title>
        <meta name="description" content={SITE_DESCRIPTION_BASE} />
        <meta property="og:image" content={SITE_URL_BASE + "/ogp.jpg"} />
        <meta property="og:url" content={SITE_URL_BASE} />
        <meta property="og:title" content={SITE_TITLE} />
        <meta property="og:description" content={SITE_DESCRIPTION_BASE}></meta>
        <meta property="og:type" content="website" />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>
      <RecoilRoot>
        <BrowserRouter>
          <div id="yeild" >
            <Header />
            <Router />
            <Footer />
          </div>
        </BrowserRouter>
        <MouseStalker />
      </RecoilRoot>
    </>
  );
}