import { useEffect, useState, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { loaded, addClass, join, getRandomInteger, qs } from '../../../modules/modules'
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import axios from 'axios';
import noimage from 'assets/img/common/noimage.jpg'

import { API_BASE_URL } from 'config/Constants';

import { activateHoverCursor, deactivateHoverCursor } from 'modules/mouseStalker'
import { useLocation } from "react-router-dom"


// Swiper
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, FreeMode, } from 'swiper';
import 'swiper/css';

type Props = {
  currentArticleId: number
}

export default function RelatedWorks(props: Props) {

  dayjs.extend(utc);
  dayjs.extend(timezone);
  dayjs.tz.setDefault('Asia/Tokyo');

  // 記事セット
  const [articles, setArticleList] = useState([]);

  const [routeUniqueKey, setRouteUniqueKey] = useState(`${Math.random()}`);


  // 関連記事取得用パラメーター
  const location = useLocation();
  const relatedLimit = 8
  const [newTotalNum, setNewTotalNum] = useState(initTotalNum());
  const [newTotalPages, setNewTotalPages] = useState(initNewTotalPage());

  function initTotalNum(): number {
    return location.state?.totalNum ? location.state?.totalNum : 7;
  }

  // 初回画面表示時に選択されているページ数を取得
  function initNewTotalPage(): number {
    const pages = initTotalNum() / relatedLimit
    return pages < 1 ? 1 : pages
  }

  function generateTargetPage() {
    const targetPage = getRandomInteger(1, newTotalPages, -1)  // 最後のページは含まない
    return targetPage < 1 ? 1 : targetPage
  }

  function composeApiUrl(): string {
    // ある程度ランダムに記事を取得    
    const query = `/works_list?limit=${relatedLimit}&page=${generateTargetPage()}`;
    const uri = API_BASE_URL + query
    return uri
  }

  // 記事一覧を取得してセット
  useEffect(() => {
    const uri = composeApiUrl();
    axios({
      method: 'get',
      url: uri,
      timeout: 10000
    })
      .then(function (res) {
        setArticleList(res.data.contents);
        setNewTotalNum(parseInt(res.data.total_pages) * relatedLimit); //このコンポーネントのlimitを基にした総ページ数 
      })
      .catch(function (error) {
        addClass(qs(".related_works_slider_wrapper.js__load_required"), "failed");
      })
      .finally(function () {
      });
  }, []);

  // サムネURLの設定
  function img_src(article: any) {
    if (article.img_src) {
      return article.img_src
    } else {
      return noimage
    }
  }

  const showArticles = useMemo(() => {
    let postTitle;
    let postUrl;
    let postCategories;

    if (articles?.length >= 1) {
      loaded(".related_works_slider_wrapper.js__load_required");

      return (
        articles.map((article: any, index: number) => {

          // 現在表示中の記事はスライダーに表示しないようにする、クリックすると固まるので
          if (props.currentArticleId !== article.id) {
            // タイトルの取得
            postTitle = String(article.title);
            // URLの取得
            postUrl = "/works/" + article.id;
            // カテゴリの取得
            postCategories = article.cat_names
            return (
              <SwiperSlide key={index}>
                <Link
                  to={postUrl}
                  className="anchor"
                  title={postTitle}
                  onMouseOver={activateHoverCursor}
                  onMouseOut={deactivateHoverCursor}
                  state={{ totalNum: newTotalNum }}
                >
                  <div className="article_info">
                    <img src={img_src(article)} alt={postTitle} />
                    <div className="info">
                      <p className="categories">{join(postCategories, "・")}</p>
                      <p className="title">{postTitle}</p>
                    </div>
                  </div>
                </Link>
              </SwiperSlide>
            )
          }

        })
      )
    }
  }, [articles])

  return (
    <>
      <div className="related_works_slider_wrapper js__load_required" key={routeUniqueKey}>
        {articles.length &&
          <>
            <Swiper
              speed={6000}
              autoplay={{
                delay: 0,
                disableOnInteraction: false,
              }}
              centeredSlides={true}
              modules={[Autoplay, FreeMode]}
              freeMode={{
                enabled: true,
                momentum: false
              }}
              onTouchEnd={(swiper) => {
                swiper.slideTo(swiper.activeIndex + 1)
              }}
              loop={true}
              slidesPerView={"auto"}
              draggable={true}

              className="related_works_swiper"
              breakpoints={{
                0: {
                  spaceBetween: 8
                },
                530: {
                  spaceBetween: 20
                },
              }}
            >
              {showArticles}
            </Swiper>

            <div className="related_works_navigation">

              <p>Other works</p>

            </div>
          </>

        }
      </div>
    </>
  );
}