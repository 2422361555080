import { useEffect, useMemo } from 'react';
import { loaded, ISOvserver } from 'modules/modules'
import { activateHoverCursor, deactivateHoverCursor } from 'modules/mouseStalker'
import NavigationButton from 'components/ui/NavigationButton'

// Swiper
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Navigation, Grid } from 'swiper';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/grid';
import 'swiper/css/navigation';


type Props = {
  estateList: any
  setSelectedEstate: any
  generateEstate: any
}

export default function EstateList(props: Props) {

  /*=======================================
    背景操作用
  =======================================*/

  // アニメーション用
  useEffect(() => {
    let observer: IntersectionObserver;
    observer = ISOvserver();
    return () => {
      observer?.disconnect();
    };
  }, []);

  // サブ画像のURLを配列で返す
  function subImagesSrc(images: any): string[] {

    if (!images) return []; // nullであれば空の配列を返す
    const length = Object.keys(images).length;
    const returnImages: string[] = [];
    if (length > 0) {
      for (let i = 0; i < length; i++) {
        const key = Object.keys(images)[i];
        const img = images[key]
        if (img) returnImages.push(images[key]);
      }
      return returnImages;
    } else {
      return [];
    }
  }

  function handleClick(e: any) {
    const title = e.target.getAttribute("data-title");
    const description = e.target.getAttribute("data-description");
    const images = e.target.getAttribute("data-images").split(",");
    props.setSelectedEstate(props.generateEstate(title, description, images))
  }


  const showSlides = useMemo(() => {
    let title: string;
    let description: string;
    let images: string[];
    if (props.estateList?.length) {
      loaded(".vurtual_estate_list.js__load_required");
      return (
        props.estateList.map((article: any, index: number) => {
          title = article.title; // タイトルを設定
          description = article.description; // タイトルを設定
          images = subImagesSrc(article.images); // サムネには1枚目の画像を設定
          return (
            <SwiperSlide key={index} className="estate_list_slides" onClick={(e) => { handleClick(e) }} data-title={title} data-description={description} data-images={images} onMouseOver={activateHoverCursor} onMouseOut={deactivateHoverCursor}>
              <div className="estate_list_slide_inner">
                <div className="estate_thumbnail_wrapper">
                  <img src={images[0]} alt={title} className="" />
                </div>
              </div>
            </SwiperSlide>
          )
        })
      )
    }
  }, [props.estateList])


  function showPcSwiper() {
    return (
      <>
        <Swiper
          centeredSlides={false}
          modules={[Navigation, Pagination, Grid]}
          draggable={true}
          spaceBetween={30}
          slidesPerGroup={2} // これがないとグリッドの順番の解釈とスライド時の塊の解釈が怪しくなる
          slidesPerView={2}
          rewind={true} // loopがgridだと上手く動かないのでrewindで対応
          grid={{
            rows: 2,
            fill: 'row',
          }}
          navigation={{
            prevEl: "#estate_list_swiper_prev_pc .c__navigation_button",
            nextEl: "#estate_list_swiper_next_pc .c__navigation_button"
          }}
          pagination={{
            clickable: true,
            el: "#estate_list_swiper_pagination_pc",
            renderBullet: function (index: any, className: any) {
              return '<span class="' + className + '"></span>';
            },
          }}
          className="estate_list_swiper estate_list_swiper_pc"
        >
          {showSlides}
        </Swiper>
        <div className="estate_list_navigations_wrapper">
          <div id="estate_list_swiper_prev_pc" className="estate_list_swiper_prev">
            <NavigationButton variant="left" />
          </div>

          <div id="estate_list_swiper_pagination_pc" className="estate_list_swiper_pagination"></div>

          <div id="estate_list_swiper_next_pc" className="estate_list_swiper_next">
            <NavigationButton variant="" />
          </div>
        </div>
      </>
    )
  }

  function showSpSwiper() {
    return (

      <>
        <div className="tab_swiper_wrapper">
          <Swiper
            centeredSlides={false}
            modules={[Navigation, Pagination]}
            draggable={true}

            slidesPerView={2}
            slidesPerGroup={2} // これがないとグリッドの順番の解釈とスライド時の塊の解釈が怪しくなる
            initialSlide={2}// これを設定しないと先頭の解釈がずれる
            loop={true}
            navigation={{
              prevEl: "#estate_list_swiper_prev_tab .c__navigation_button",
              nextEl: "#estate_list_swiper_next_tab .c__navigation_button"
            }}
            pagination={{
              clickable: true,
              el: "#estate_list_swiper_pagination_tab",
              renderBullet: function (index: any, className: any) {
                return '<span class="' + className + '"></span>';
              },
            }}
            className="estate_list_swiper estate_list_swiper_tab"
            breakpoints={
              {
                0: {
                  spaceBetween: 8
                },
                376: {
                  spaceBetween: 15
                },
                530: {
                  spaceBetween: 20
                },
              }
            }
          >
            {showSlides}
          </Swiper>
          <div className="estate_list_navigations_wrapper">


            <div id="estate_list_swiper_prev_tab" className="estate_list_swiper_prev">
              <NavigationButton variant="left" />
            </div>

            <div id="estate_list_swiper_pagination_tab" className="estate_list_swiper_pagination"></div>

            <div id="estate_list_swiper_next_tab" className="estate_list_swiper_next">
              <NavigationButton variant="" />
            </div>
          </div>
        </div>
      </>
    )
  }

  return (
    <div className="vurtual_estate_list js__load_required">
      {/* gridの有無が変わる場合はブレイクポイントが使えなくなるため別々に定義 */}
      {showPcSwiper()}
      {showSpSwiper()}
    </div >
  );
}