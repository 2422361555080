import { useCallback } from 'react'
import { useSetRecoilState, useRecoilValue } from "recoil"
import { FirstViewAtom } from "../atom/GlobalAtom"
import { FirstVisitedAtom } from "../atom/GlobalAtom"

// 背景のstateを管理
type Background = State & {
  scrolled: () => void;
  start: () => void;
  visited: () => void;
};

type State = {
  isScrolled: boolean;
  isFirstVisit: boolean;
};

const useFirstView = (): Background => {

  const isScrolled = useRecoilValue(FirstViewAtom);
  const setIsScrolled = useSetRecoilState(FirstViewAtom);
  const scrolled = useCallback(() => setIsScrolled(true), []);
  const start = useCallback(() => setIsScrolled(false), []);

  const isFirstVisit = useRecoilValue(FirstVisitedAtom);
  const setIsFirstVisit = useSetRecoilState(FirstVisitedAtom);
  const visited = useCallback(() => setIsFirstVisit(false), []);

  return {
    isScrolled,
    scrolled,
    start,
    isFirstVisit,
    visited,
  };
}
export default useFirstView;