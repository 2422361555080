import { Link } from 'react-router-dom';
import { activateHoverCursor, deactivateHoverCursor } from 'modules/mouseStalker'

type Props = {
  mode: string
  path?: string
  external?: boolean
  variant?: string
  children?: React.ReactNode
  [key: string]: any;
}

export default function PrimaryButton({ ...props }: Props,) {

  function generate() {

    const variant = props.variant ? " " + props.variant : ""

    if (props.mode === "link") {
      if (props.external) {
        return (
          <a href={props.path} className={"c__primary_button" + variant} target="_blank" rel="noopener noreferrer" onMouseOver={activateHoverCursor} onMouseOut={deactivateHoverCursor} {...props}>
            {props.children}
          </a>
        )
      } else {
        return (
          <Link to={props.path} className={"c__primary_button" + variant} onMouseOver={activateHoverCursor} onMouseOut={deactivateHoverCursor} {...props}>
            {props.children}
          </Link>
        )
      }
    }
    if (props.mode === "button") {
      return (
        <button className={"c__primary_button" + variant} onMouseOver={activateHoverCursor} onMouseOut={deactivateHoverCursor} {...props} >
          {props.children}
        </button>
      )
    }
  }

  return (
    generate()
  );
}