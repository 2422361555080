import { Link, useNavigate } from 'react-router-dom';
import useCurrentPage from 'hooks/useCurrentPage'
import useBackground from 'hooks/useBackground'
import { scrollToSelector } from 'modules/modules'
import { activateHoverCursor, deactivateHoverCursor } from 'modules/mouseStalker'
import useFirstView from 'hooks/useFirstView'

type Props = {
}
export default function Footer(props: Props) {

  const currentPage = useCurrentPage();
  const navigate = useNavigate();

  const background = useBackground();
  const fv = useFirstView();

  /*=======================================
    クリック制御
  =======================================*/
  // 背景非活性化
  function handleClickLink(newPageName: string) {
    if (currentPage !== newPageName) background.deactivate();
    if (fv.isFirstVisit) fv.visited();
  };

  // アンカーリンク制御
  function scrollToWorks(e: any) {
    e.preventDefault();
    if (currentPage === "home") {
      navigate("/#works")
      scrollToSelector("#works");
    } else {
      background.deactivate();
      if (fv.isFirstVisit) fv.visited();
      if (!fv.isScrolled) fv.scrolled();
      navigate("/#works")
      setTimeout(() => {
        scrollToSelector("#works");
      }, 800);
    }
  }

  return (
    <>
      <footer id="footer" className={background.isActive ? "active" : ""}>

        <div className="footer_inner">

          <nav className="footer_l">

            <Link to="/#works" onClick={(e) => scrollToWorks(e)} onMouseOver={activateHoverCursor} onMouseOut={deactivateHoverCursor}>
              Works
            </Link>

            <Link to="/about" onClick={() => handleClickLink("about")} onMouseOver={activateHoverCursor} onMouseOut={deactivateHoverCursor}>
              About
            </Link>

            <Link to="/contact" onClick={() => handleClickLink("contact")} onMouseOver={activateHoverCursor} onMouseOut={deactivateHoverCursor}>
              Contact
            </Link>

          </nav>

          <div className="footer_r">
            <Link to="/terms" onClick={() => handleClickLink("terms")} onMouseOver={activateHoverCursor} onMouseOut={deactivateHoverCursor}>
              Privacy policy
            </Link>
            <p className="copyright">
              Copyright NIN, LLC
            </p>
          </div>
        </div>

      </footer>


    </>
  );
}