import SpMenu from './SpMenu';
import { useEffect } from 'react';

import { Link, useNavigate } from 'react-router-dom';
import logo_wh from 'assets/img/common/logo_wh.svg'
import useCurrentPage from 'hooks/useCurrentPage'
import { scrollTo, } from 'modules/modules'
import useBackground from 'hooks/useBackground'
import { activateHoverCursor, deactivateHoverCursor } from 'modules/mouseStalker'
import useFirstView from 'hooks/useFirstView'
import { qsAll, removeClassAll, scrollToSelector } from '../../../modules/modules'

type Props = {
}

export default function Header(props: Props) {

  const currentPage = useCurrentPage();

  const navigate = useNavigate();

  const background = useBackground();

  const fv = useFirstView();


  /*=======================================
    トップ以外またはFV再生済みであれば初めからヘッダーを表示
  =======================================*/
  useEffect(() => {
    if (currentPage !== "home" || !fv.isFirstVisit) {
      removeClassAll(qsAll("#header .header_r, #header .header_l, #header_toggle"), 'disabled');
    };
  }, [fv.isFirstVisit]);


  /*=======================================
    ロゴクリック時の制御
  =======================================*/
  // ローディングアニメーションは見せたい場合があるので、
  // あえてここではFVに関する処理はしない
  function handleClickLogo() {
    if (currentPage === "home") {
      scrollTo(0);
    };
  };

  /*=======================================
    クリック制御
  =======================================*/
  function handleClickLink(newPageName: string) {
    if (fv.isFirstVisit) fv.visited();
    if (!fv.isScrolled) fv.scrolled();
    if (currentPage !== newPageName) background.deactivate();
  };



  // アンカーリンク制御
  function scrollToWorks(e: any) {
    e.preventDefault();
    if (currentPage === "home") {
      if (fv.isFirstVisit) fv.visited();
      if (!fv.isScrolled) fv.scrolled();
      navigate("/#works")
      scrollToSelector("#works");
    } else {
      background.deactivate();
      if (fv.isFirstVisit) fv.visited();
      if (!fv.isScrolled) fv.scrolled();
      navigate("/#works")
      setTimeout(() => {
        scrollToSelector("#works");
      }, 800);
    }
  }



  return (
    <>
      <header id={"header"}>

        {/* FVでヘッダーを非表示にしたいので始めからdisabledクラスを付与 */}
        <div className="header_l disabled">

          <Link to="/" className="logo_anchor" onClick={() => handleClickLogo()} onMouseOver={activateHoverCursor} onMouseOut={deactivateHoverCursor} >
            <img src={logo_wh} className="white" alt="" width={290} height={58} />
          </Link>

        </div>

        {/* FVでヘッダーを非表示にしたいので始めからdisabledクラスを付与 */}
        <nav className="header_r disabled">

          <div className="link_wrapper">
            <Link to="/#works" onClick={(e) => scrollToWorks(e)} onMouseOver={activateHoverCursor} onMouseOut={deactivateHoverCursor}>
              Works
            </Link>

            <Link to="/about" onClick={() => handleClickLink("about")} onMouseOver={activateHoverCursor} onMouseOut={deactivateHoverCursor}>
              About
            </Link>

            <Link to="/contact" onClick={() => handleClickLink("contact")} onMouseOver={activateHoverCursor} onMouseOut={deactivateHoverCursor}>
              Contact
            </Link>

          </div>

        </nav>



        <SpMenu scrollToWorks={scrollToWorks} />
      </header>
    </>
  );
}