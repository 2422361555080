import { useEffect, } from 'react';
import { scrollToSelector } from "modules/modules"
import PrimaryHeading from 'components/headings/PrimaryHeading'
import WorksList from 'components/models/works/WorksList'
import useFirstView from 'hooks/useFirstView'
import { useLocation } from 'react-router-dom';
type Props = {
}

export default function Works(props: Props) {

  const location = useLocation();
  const fv = useFirstView();

  // ハッシュにworksが含まれていたら、FVの訪問&スクロールフラグを立てる
  useEffect(() => {
    if (fv.isFirstVisit && location.hash.includes("works")) {
      fv.visited();
      fv.scrolled();
      scrollToSelector("#works");
    }
  }, []);

  return (
    <>
      <div id="" className="home_works">

        <PrimaryHeading letter="WORKS" />
        <WorksList limit={12} pagenate={true} category={true} />

      </div>
    </>
  );
}
