import axios from 'axios'
import { useEffect, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import { motion } from "framer-motion"
import { Helmet } from "react-helmet-async"
import noimage from 'assets/img/common/noimage.jpg'
import { getParam, ISOvserver, unEscapeHtml } from 'modules/modules'
import PrimaryArticle from 'components/layouts/articles/PrimaryArticle'
import RelatedWorks from './RelatedWorks'
import useBackground from "hooks/useBackground"
import { API_BASE_URL } from 'config/Constants';
import { Article } from "components/models/works/Types";

type Props = {
}

const initialArticle: Article = {
  title: "",
  publishedDate: "",
  categories: [],
  image_url: "",
  content: "",
  excerpt: "",
  caption: "",
  credit: ""
};

const Show = (props: Props) => {

  const { id } = useParams(); //ルーティングで使っているパラメータ
  const [article, setArticle] = useState<Article>(initialArticle);

  const navigate = useNavigate();


  dayjs.extend(utc);
  dayjs.extend(timezone);
  const background = useBackground();

  // 記事詳細を取得
  useEffect(() => {
    // loading();
    background.deactivate()

    const previewQuery = getParam("previewMode") === "on" ? "&previewMode=on" : "";
    const query = `/work_detail/?id=${id}${previewQuery}`;
    const uri = API_BASE_URL + query

    axios({
      method: 'get',
      url: uri,
      timeout: 10000
    })
      .then(function (res) {

        // 公開日を初期化、無効な日付だった場合はとりあえず現在の年
        let date = dayjs(res.data.date).format('YYYY');
        if (date === "Invalid Date") {
          date = dayjs().format('YYYY')
        }

        const artice: Article = {
          title: String(res.data.title),
          publishedDate: date,
          categories: res.data.cat_names,
          image_url: img_src(res.data),
          content: res.data.content,
          excerpt: res.data.caption + unEscapeHtml(res.data.excerpt),
          caption: res.data.caption,
          credit: res.data.credit,
        };

        // 記事情報のセット
        setArticle(artice);
      })
      .catch(function (e) {
        navigate('/notfound')
      })
      .finally(function () {
      });
  }, []);

  // アニメーション用
  useEffect(() => {
    let observer: IntersectionObserver;
    observer = ISOvserver();
    return () => {
      observer?.disconnect();
    };
  }, []);

  function img_src(article: any) {
    if (article.img_src) {
      return article.img_src
    } else {
      return noimage
    }
  }

  return (
    <>
      <Helmet prioritizeSeoTags>
        <meta property="og:image" content={article.image_url} />
        <title>{article.title} Works - 合同会社NIN</title>
        <meta property="og:title" content={article.title + " Works - 合同会社nin"} />
        <meta property="og:description" content={article.excerpt} />
        <meta name="description" content={article.excerpt} />
        <meta property="og:url" content={"https://ninworks.com/works/" + id} />
      </Helmet>

      <motion.div
        initial={{ opacity: 0 }} // このページに遷移した直後
        animate={{ opacity: 1 }} // 最終的な状態
        exit={{ opacity: 0 }} // 離れる際にどうなるか
        transition={{ duration: 0.3 }} // 切り替えに要する時間
      >
        <main id="p_works_show" className="">
          <PrimaryArticle article={article} />
          <RelatedWorks currentArticleId={Number(id)} />
        </main>
      </motion.div>
    </>
  );
}
export default Show;