import { useEffect, useState, } from 'react';
import { loaded, qs, ISOvserver, addClassAll, removeClassAll, qsAll } from 'modules/modules'
import { addStalkerHoverEvent, removeStalkerHoverEvent, } from "modules/mouseStalker"
import virtual_estate_logo from 'assets/img/common/virtual_estate_logo.svg'

// Swiper
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination, EffectFade } from 'swiper';
import 'swiper/css';
import 'swiper/css/pagination';
import "swiper/css/effect-fade";
import 'swiper/css/navigation';


type Props = {
  selectedEstate: SelectedEstate
}
type SelectedEstate = {
  title: string, description: string, images: string[]
}
export default function EstateDetail(props: Props) {

  /*=======================================
    背景操作・記事内リンクに対しての設定
  =======================================*/

  // 読み込まれた時の設定
  useEffect(() => {
    let observer: IntersectionObserver;
    observer = ISOvserver();
    loaded(".vurtual_estate_detail.js__load_required");
    return () => {
      observer?.disconnect();
    };
  }, []);


  // ディレイをかけるためにあえて自コンポーネントで保持
  const [selectedEstate, setSelectedEstate] = useState<SelectedEstate>(props.selectedEstate);

  // 選択された物件が変更されたらスライダーのインデックスを初期化
  useEffect(() => {
    const anchorSelector: string = ".c__article_body a"
    const target = qsAll(".js__switch");
    const swiper = qs('.estate_detail_swiper').swiper;
    const zeroIndex = 0;
    addClassAll(target, "loading")

    // 以下、ある程度CSSとタイミングを合わせる
    // 記事が非表示になるまで切り替えを遅延させる
    setTimeout(() => {
      setSelectedEstate(props.selectedEstate);
      if (!swiper.destroyed) swiper.slideToLoop(zeroIndex, 0);
    }, 480);

    // 表示する際にホバーの反応と外部リンクで開く設定を追加
    setTimeout(() => {
      removeClassAll(target, "loading")
      addStalkerHoverEvent(anchorSelector);
      const anchors = qsAll(anchorSelector);
      for (const anchor of anchors) {
        anchor.setAttribute("target", "_blank");
        anchor.setAttribute("rel", "noopener noreferrer");
      }
    }, 500);

    return () => {
      removeStalkerHoverEvent(anchorSelector);
    };
  }, [props.selectedEstate]);

  return (
    <>

      <div className="vurtual_estate_detail js__switch">
        <Swiper
          autoplay={{
            delay: 3000,
            disableOnInteraction: false,
          }}
          centeredSlides={true}
          loop={true}
          slidesPerView={1}
          initialSlide={1}// これを設定しないと先頭の解釈がずれる
          draggable={true}
          effect={"fade"}
          modules={[Pagination, EffectFade, Autoplay]}
          pagination={{
            clickable: true,
            el: "#estate_detail_swiper_pagination",
            renderBullet: function (index: any, className: any) {
              return '<span class="' + className + '"></span>';
            },
          }}
          className="estate_detail_swiper "
        >

          {selectedEstate.images.map((image: string, index: number) => {
            if (image?.length) {
              return (
                <SwiperSlide key={index + "estate_detail_img"} className="estate_detail_slides">
                  <img src={image} className="estate_detail_img" alt="" />
                </SwiperSlide>
              )
            }
          })}

        </Swiper>

        <img src={virtual_estate_logo} alt="ヲタきち不動産" className="virtual_estate_logo" />

        <div id="estate_detail_swiper_pagination"></div>
        <div className="estate_info ">
          <p className="title">{selectedEstate.title}</p>
          <div className="c__article_body description" dangerouslySetInnerHTML={{ __html: selectedEstate.description }} />

        </div>

      </div>






    </>
  );
}