
type Props = {
}

export default function Information(props: Props) {

  return (
    <>
      <div className="about_company_information c__js_fade">

        <div className="company_information_item no_decoration">
          <p className="head section_head">会社概要</p>
        </div>
        <div className="company_information_item">
          <p className="head">代表社員</p>
          <p className="description">小名 良平</p>

        </div>
        <div className="company_information_item">
          <p className="head">会社名</p>
          <p className="description"><span className="u__ib">合同会社NIN</span><span className="u__ib">（ゴウドウガイシャニン）</span></p>

        </div>
        <div className="company_information_item">
          <p className="head">資本金</p>
          <p className="description">222 万円</p>
        </div>
        <div className="company_information_item bottom_decoration sp_deco_bottom_none">
          <p className="head">事業内容</p>
          <p className="description"><span className="u__ib">映像企画・制作 ／ </span> <span className="u__ib">コンサルタント業</span></p>

        </div>
        <div className="company_information_item bottom_decoration">
          <p className="head">設立年度</p>
          <p className="description">2023 年 5 月 2 日</p>
        </div>


      </div>
    </>
  );
}
