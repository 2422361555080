import { useEffect, useState } from 'react';
import {  getParam,  } from '../../../modules/modules'
import axios from 'axios';
import PrimaryHeading from 'components/headings/PrimaryHeading'
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { activateHoverCursor, deactivateHoverCursor } from 'modules/mouseStalker'
import { API_BASE_URL } from 'config/Constants';

type Props = {
}

export default function News(props: Props) {

  dayjs.extend(utc);
  dayjs.extend(timezone);
  dayjs.tz.setDefault('Asia/Tokyo');

  // 記事セット
  const [articles, setArticleList] = useState([]);

  // ページング系
  const limit = 100;
  const currentPage = 1;
  const [routeUniqueKey, setRouteUniqueKey] = useState(`${Math.random()}`);

  function composeApiUrl(): string {
    const previewModeQuery = getParam("previewMode") === "on" ? "&previewMode=on" : "";
    const query = `/news_list?limit=${limit}&page=${currentPage}${previewModeQuery}`
    const uri = API_BASE_URL + query
    return uri
  }

  // 記事一覧を取得してセット
  useEffect(() => {
    const uri = composeApiUrl();

    axios({
      method: 'get',
      url: uri,
      timeout: 10000
    })
      .then(function (res) {
        setArticleList(res.data.contents);
      })
      .catch(function (error) {
        // 取得に失敗したエラーが表示される
      })
      .finally(function () {
      });

  }, [routeUniqueKey]);


  function showArticles(): any {
    let postTitle;
    let postUrl;
    let publishedDate;
    let externalLink;
    if (articles?.length) {
      return (
        articles.map((article: any, index: number) => {
          postTitle = article.title; // タイトルを設定
          externalLink = article.external_link; // URLを設定
          publishedDate = dayjs(article.date).format('YY/MM/DD');
          return (
            <a className="news_item" key={index} href={externalLink} target="_blank" rel="noopener noreferrer" title={postTitle} onMouseOver={activateHoverCursor} onMouseOut={deactivateHoverCursor}>
              <p className="date">{publishedDate}</p>
              <p className="title">{postTitle}</p>
            </a>
          )
        })
      )
    } else {
      return (
        <p className="c__article_notfound c__common_text">
          データの取得に失敗しました。<br />
          時間をおいて再度お試しください。
        </p>
      )
    }
  }

  return (
    <>

      <div className="about_news c__js_fade">
        <PrimaryHeading letter="news" />

        <div className="about_news_wrapper">
          <div className={"about_news_inner" + (articles?.length >= 5 ? " overflow" : "" ) }>
            {showArticles()}
          </div>
        </div>


      </div>

    </>
  );
}