
/*=======================================
  IntersectionObserver系
=======================================*/

// IntersectionObserver共通関数
export function ISOvserve(targetSelector: string | string[], options: any, activeFunc: any, inactiveFunc: any = () => { }): IntersectionObserver | null {

  let observer = new IntersectionObserver(callback(activeFunc, inactiveFunc), options);

  if (Array.isArray(targetSelector)) {

    // セレクタが配列の場合は複数の要素を監視する
    const targets = qsAll(targetSelector.join(", "));

    // 空の場合は何もしない
    if (targets.length === 0) {
      return null;
    }

    // 空でなければ監視する
    for (const target of targets) {
      observer.observe(target);
    }

  } else {
    // セレクタが単体の場合は1つの要素を監視する
    const target = qs(targetSelector);
    // 空の場合は何もしない
    if (!target) {
      return null;
    }
    observer.observe(target);
  }
  return observer;

  function callback(activeFunc: any, inactiveFunc: any) {
    return function (entries: any) {
      for (const elm of entries) {
        if (elm.isIntersecting) {
          activeFunc(elm);
        } else {
          inactiveFunc(elm);
        }
      }
    }
  }
}

export function ISOvserver(selector: string | string[] = ""): IntersectionObserver {
  const targetSelectors = selector ? selector : [".c__js_fade", ".c__js_fade_delay", ".c__js_blur", ".js__transition", ".js__animation", ".js__observe", ".c__js_highlights"];
  // ネガティブマージンにすると当たり判定を狭められて、
  // 普通のマージンにすると当たり判定を画面外まで広げられる
  const options = {
    rootMargin: "0% 0% -40% 0%", // ビューポートの真ん中ぐらい
  };
  function activeFunc(elm: any) {
    elm.target.classList.add("active");
  }
  return ISOvserve(targetSelectors, options, activeFunc)
}

export function ISOvserverRepeats(selector: string | string[] = ""): IntersectionObserver {
  const targetSelectors = selector ? selector : [".js__observe_repeat", ".c__js_fade_repeat"];
  const options = {
    rootMargin: "0% 0% -40% 0%", // ビューポートの真ん中ぐらい
  };
  function activeFunc(elm: any) {
    elm.target.classList.add("active");
  }
  function inactiveFunc(elm: any) {
    elm.target.classList.remove("active");
  }
  return ISOvserve(targetSelectors, options, activeFunc, inactiveFunc)
}

/*=======================================
  その他関数
=======================================*/
export function setSvh(): void {
  setTimeout(() => {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--svh', `${vh}px`)
  }, 500);
}
export function setStaticSvh(): void {
  setTimeout(() => {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--static_svh', `${vh}px`);
  }, 500);
}
export function toggleClass(element: any, className: string): void {
  if (element.className.includes(className)) {
    element.classList.remove(className);
  } else {
    element.classList.add(className);
  }
}
export function scrollTo(offset: number): void {
  window.scrollTo({
    top: offset,
    behavior: "smooth"
  });
}
export function scrollToSelector(sel: string): void {
  const target = qs(sel)
  if (target) scrollTo(target.offsetTop)
}
export function getHeaderHeight(): number {
  const pc_header_selector = "#header .header_l";
  const pcHeaderHeight = qs(pc_header_selector)!.offsetHeight;
  return pcHeaderHeight + 40;
}
export function getParam(param: string): string {
  try {
    let url = new URL(window.location.href);
    let value = url.searchParams.get(param);
    return value ? value : "";
  }
  catch (e) {
    return "";
  }
}
export function getParamFromUrlString(urlString: string, param: string): string {
  try {
    let url = new URL(urlString);
    let value = url.searchParams.get(param);
    return value ? value : "";
  }
  catch (e) {
    return "";
  }
}
export function getPath(): string {
  return window.location.pathname;
}
export function getHash(): string {
  return window.location.hash;
}
export function getPathHash(): string {
  return window.location.pathname + window.location.hash;
}
export function isActive(element: HTMLElement): boolean {
  return element?.className.includes("active");
}
export function qs(name: string, elm = document): any {
  return elm?.querySelector(name);
}
export function qsAll(name: string, elm: any = document): any {
  return elm?.querySelectorAll(name);
}
export function hasClass(elm: HTMLElement | null, name: string): boolean {
  return !!elm?.className.includes(name); // undefinedの場合はfalseにする
}
export function addClass(elm: HTMLElement | null, name: string): void {
  return elm?.classList.add(name);
}
export function removeClass(elm: HTMLElement | null, name: string): void {
  if (elm && hasClass(elm, name)) {
    elm.classList.remove(name);
  }
}
export function addClassAll(elms: Array<HTMLElement>, name: string): void {
  if (elms.length === 0) return;
  for (const elm of elms) {
    elm?.classList.add(name);
  }
}
export function removeClassAll(elms: Array<HTMLElement>, name: string): void {
  if (elms.length === 0) return;
  for (const elm of elms) {
    if (elm && hasClass(elm, name)) {
      elm.classList.remove(name);
    }
  }
}
export function loading(selector: string = ""): void {
  let targets;
  if (selector) {
    targets = qsAll(selector);
  } else {
    targets = qsAll(".js__load_required");
  }
  for (const elm of targets) {
    removeClass(elm, "loaded");
    removeClass(elm, "failed");
  }
}
export function loaded(selector: string = ""): void {
  setTimeout(() => {
    let targets;
    if (selector) {
      targets = qsAll(selector);
    } else {
      targets = qsAll(".js__load_required");
    }
    for (const elm of targets) {
      addClass(elm, "loaded");
    }
  }, 500);
}
export function unEscapeHtml(escapedText: string) {
  // 参考リンクhttps://blog.kimizuka.org/entry/2022/02/28/214035
  const textarea = document.createElement('textarea');
  textarea.innerHTML = escapedText;
  return textarea.value;
}

// デフォルトのjoinが空を考慮してくれないので自作
export function join(array: string[] | number[] | undefined | null, separator: string): string {
  if (array && array.length) {
    return array.join(separator);
  } else {
    return "";
  }
}
// 改行コードをbrタグに変換し、jsxにする関数
export function nl2br(text: string) {
  if (!text) return "";
  const regex = /(\n)/g;
  const result = text.split(regex).map((line, index) => {
    if (line.match(regex)) {
      return <br key={index} />;
    } else {
      return line;
    }
  });
  return result;
}

/**
* ランダムな正の整数を生成
* @param {number} min - 最小値
* @param {number} max - 最大値
* @param {number} offset - 結果を若干上振れor下振れさせたい場合に使用
* @return {number} ランダムな整数
* @note offsetに小数を渡した場合は小数が返却される
* @note 0が返却される可能性もあり
*/
export function getRandomInteger(min: number, max: number, offset: number = 0) {
  const num = (Math.floor(Math.random() * (max + 1 - min)) + min) + offset;
  return num <= 0 ? 0 : num;
}

/**
* 小数点の桁数をを指定し、四捨五入して返す
* @param {number} number - 対象となる数値 
* @param {number} digit - 小数点の桁数
* @return {number} 四捨五入した値
* @note Math.pow() は累乗を算出する関数で、 Math(x, y) はxのy乗を表す
*/
export function round(number: number, digit: number) {
  const magnification = Math.pow(10, digit);
  return Math.round(number * magnification) / magnification;
};
