const shift = 3;

// @ts-ignore
function c(arg) {
  let s = "";
  for (var i = 0; i < arg.length; i++) {
    s += String.fromCharCode(arg.charCodeAt(i) + shift);
  }
  return s;
}

// @ts-ignore
export function d(arg) {
  let s = "";
  for (var i = 0; i < arg?.length; i++) {
    s += String.fromCharCode(arg.charCodeAt(i) - shift);
  }
  return s;
}