import { motion } from "framer-motion";
import { Helmet } from "react-helmet-async"
import { useEffect, useState } from 'react';
import PrimaryHeading from 'components/headings/PrimaryHeading'

import { useNavigate } from 'react-router-dom';

import axios from 'axios'
import {  getParam, ISOvserver } from 'modules/modules'
import { addStalkerHoverEvent, removeStalkerHoverEvent,  } from "modules/mouseStalker"
import useBackground from "hooks/useBackground"
import EstateList from './EstateList';
import EstateDetail from './EstateDetail';
import { SITE_TITLE,  SITE_URL_BASE } from 'config/Constants';
import { API_BASE_URL } from 'config/Constants';

import roof from 'assets/img/virtual_estate/roof_pc.svg'
import roof_sp from 'assets/img/virtual_estate/roof_sp.svg'


type Props = {
}

type SelectedEstate = {
  title: string, description: string, images: string[]
}

export default function VirtualEstate(props: Props) {
  const navigate = useNavigate();

  /*=======================================
    背景操作用
  =======================================*/
  const background = useBackground();

  // アニメーション用
  useEffect(() => {
    let observer: IntersectionObserver;
    observer = ISOvserver();
    return () => {
      observer?.disconnect();
    };
  }, []);

  // マウスストーカー用
  useEffect(() => {
    const selector = "#estate_list_swiper_pagination span , #estate_detail_swiper_pagination span";
    addStalkerHoverEvent(selector);
    return () => {
      removeStalkerHoverEvent(selector);
    }
  }, []);



  const [estateList, setEstateList] = useState([]);
  const [selectedEstate, setSelectedEstate] = useState<SelectedEstate>(generateEstate("", "", [""]));

  function composeApiUrl(): string {
    const previewModeQuery = getParam("previewMode") === "on" ? "&previewMode=on" : "";
    const query = `/virtual_estate?${previewModeQuery}`
    const uri = API_BASE_URL + query
    return uri
  }

  // 記事詳細を取得
  useEffect(() => {
    const uri = composeApiUrl();
    axios({
      method: 'get',
      url: uri,
      timeout: 10000
    })
      .then(function (res) {
        const contents = res.data.contents;
        setEstateList(contents);

        // とりあえず一件目をセット
        setSelectedEstate(generateEstate(contents[0].title, contents[0].description, contents[0].images))

      })
      .catch(function (e) {
        navigate('/notfound')

      })
      .finally(function () {
        background.activate()
      });
  }, []);


  function generateEstate(title: string, description: string, images: any): SelectedEstate {
    return { title: title, description: description, images: subImagesSrc(images) };
  }


  // サブ画像のURLを配列で返す
  function subImagesSrc(images: any): string[] {

    if (!images) return []; // nullであれば空の配列を返す
    const length = Object.keys(images).length;
    const returnImages: string[] = [];
    if (length > 0) {
      for (let i = 0; i < length; i++) {
        const key = Object.keys(images)[i];
        const img = images[key]
        if (img) returnImages.push(images[key]);
      }
      return returnImages;
    } else {
      return [];
    }
  }

  return (
    <>
      <Helmet>
        <title>{`ヲタきち不動産｜${SITE_TITLE}`}</title>
        <meta name="description" content="「ヲタきち不動産」としてVTuberさんのお家をデザインしています。Unityを使いオーダーメイドでスタジオ制作し、iFacialMocapでモーション操作します。スタジオ制作のご依頼はお気軽にお問い合わせください！" />
        <meta property="og:url" content={SITE_URL_BASE + "/vurtual_estate"} />
        <meta property="og:title" content={`ヲタきち不動産｜${SITE_TITLE}`} />
      </Helmet>
      <motion.div
        initial={{ opacity: 0, }} // このページに遷移した直後
        animate={{ opacity: 1 }} // 最終的な状態
        exit={{ opacity: 0 }} // 離れる際にどうなるか
        transition={{ duration: 0.3 }}
      >
        <main id="p__virtual_estate" className="l__main_content">

          <img src={roof} className="roof_img_pc" alt="" />
          <img src={roof_sp} className="roof_img_sp" alt="" />
          <PrimaryHeading letter="vurtual_estate" />

          <div className="vurtual_estate_inner">
            <EstateList estateList={estateList} setSelectedEstate={setSelectedEstate} generateEstate={generateEstate} />
            <EstateDetail selectedEstate={selectedEstate} />
          </div>

        </main>
      </motion.div>
    </>
  );
}