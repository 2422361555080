import { Link, } from 'react-router-dom';
import { useEffect } from 'react';
import { qs, scrollTo, hasClass, setSvh, removeClass, addClass, } from 'modules/modules'
import logo_wh from 'assets/img/common/logo_wh.svg'
import useCurrentPage from 'hooks/useCurrentPage'
import { activateHoverCursor, deactivateHoverCursor } from 'modules/mouseStalker'
import useBackground from 'hooks/useBackground'
import useFirstView from 'hooks/useFirstView'

type Props = {
  scrollToWorks: Function;
}

let toggle: any;
let sp_menu: any;
let header_l: any;

export default function SpMenu(props: Props) {

  const currentPage = useCurrentPage();
  const background = useBackground();
  const fv = useFirstView();

  /*=======================================
    クリック制御
  =======================================*/
  // 移動先を制御
  function handleClickLink(e: any, targetPage: string) {
    toggleMenu();
    if (targetPage === "home_works") props.scrollToWorks(e);
    if (targetPage === "home" && currentPage === "home") scrollTo(0)
    if (currentPage !== targetPage) background.deactivate();
    if (fv.isFirstVisit) fv.visited();
    if (!fv.isScrolled) fv.scrolled();
  };


  /*=======================================
    SPメニュー制御
  =======================================*/

  // 要素取得
  useEffect(() => {
    toggle = qs("#header_toggle");
    sp_menu = qs("#sp_menu");
    header_l = qs(".header_l");
    return arrowScroll();
  }, []);

  // SPメニューの開閉を制御
  function toggleMenu() {
    setSvh();

    if (hasClass(toggle, "active")) {
      removeClass(toggle, "active");
      removeClass(sp_menu, "active");
      removeClass(header_l, "disabled");
      arrowScroll();
    } else {
      preventScroll();
      addClass(toggle, "active");
      addClass(sp_menu, "active");
      addClass(header_l, "disabled");
    }
  }

  // スクロール禁止
  function preventScroll() {
    document.addEventListener("wheel", preventDef, { passive: false });
    document.addEventListener("touchmove", preventDef, { passive: false });
  }

  // スクロール禁止解除
  function arrowScroll() {
    document.removeEventListener("wheel", preventDef);
    document.removeEventListener("touchmove", preventDef);
  }

  // preventDefault
  function preventDef(e: any) {
    e.preventDefault();
  }

  return (
    <>
      {/* FVでヘッダーを非表示にしたいので始めからdisabledクラスを付与 */}
      <button id="header_toggle" className="disabled" onClick={toggleMenu} onMouseOver={activateHoverCursor} onMouseOut={deactivateHoverCursor}>
        <span></span>
        <span></span>
        <span></span>
      </button>

      <nav id="sp_menu" >
        <div className="menu_inner">

          <Link to="/" className="link_name" onClick={(e) => handleClickLink(e, "home")} onMouseOver={activateHoverCursor} onMouseOut={deactivateHoverCursor}>
            Top
          </Link>

          <Link to="/#works" className="link_name" onClick={(e) => handleClickLink(e, "home_works")} onMouseOver={activateHoverCursor} onMouseOut={deactivateHoverCursor}>
            Works
          </Link>

          <Link to="/about" className="link_name" onClick={(e) => handleClickLink(e, "about")} onMouseOver={activateHoverCursor} onMouseOut={deactivateHoverCursor}>
            About
          </Link>

          <Link to="/contact" className="link_name" onClick={(e) => handleClickLink(e, "contact")} onMouseOver={activateHoverCursor} onMouseOut={deactivateHoverCursor}>
            Contact
          </Link>

        </div>

        <div className="sp_menu_bottom_info">
          <img src={logo_wh} className="info_l" alt="" width={290} height={58} />
          <div className="info_r">
            <Link to="/terms" onClick={(e) => handleClickLink(e, "terms")} onMouseOver={activateHoverCursor} onMouseOut={deactivateHoverCursor}>
              Privacy policy
            </Link>
            <p className="copyright">
              Copyright NIN, LLC
            </p>
          </div>
        </div>
      </nav>
    </>
  );
}