import { useEffect, } from 'react';
import { ISOvserve } from 'modules/modules'
import virtual_estate_logo_frame from 'assets/img/common/virtual_estate_logo_frame.svg'
import virtual_estate_logo_inner from 'assets/img/common/virtual_estate_logo_inner.svg'
import { Link, } from 'react-router-dom';
import useCurrentPage from 'hooks/useCurrentPage'
import useBackground from 'hooks/useBackground'
import useEstateButton from 'hooks/useEstateButton'
import { activateHoverCursor, deactivateHoverCursor } from 'modules/mouseStalker'
import useFirstView from 'hooks/useFirstView'

type Props = {
}

export default function VirtualEstateButton(props: Props) {

  const currentPage = useCurrentPage();

  const background = useBackground();
  const estateButton = useEstateButton();
  const fv = useFirstView();

  /*=======================================
    クリック制御
  =======================================*/
  // 背景非活性化
  function handleClickLink() {
    background.deactivate();
  };


  /*=======================================
  スクロールによる表示非表示の制御
=======================================*/
  useEffect(() => {
    let observer: IntersectionObserver = null;
    if ((currentPage === "home") && fv.isScrolled) {
      const targetSelectors = '#footer';
      const options = {
        rootMargin: "0% 0% -10% 0%",
      };
      observer = ISOvserve(
        targetSelectors,
        options,
        () => { estateButton.deactivate(); }, // フッターと交差したら非表示
        () => { estateButton.activate(); }, // フッターと交差していなければ表示
      )
    }
    return () => {
      observer?.disconnect();
    };
  }, [fv.isScrolled]);

  return (
    <>
      <div id="c__virtual_estate_button" className={estateButton.isActive ? " active" : ""} >
        <Link to="/virtual_estate" onClick={handleClickLink} onMouseOver={activateHoverCursor} onMouseOut={deactivateHoverCursor}>
          <img src={virtual_estate_logo_inner} alt="ヲタきち不動産" className='inner' />
          <img src={virtual_estate_logo_frame} alt="ヲタきち不動産" className='frame' />
        </Link>
      </div>
      <div id="pagetop_observer"></div>

    </>
  );
}