import { useEffect, useState } from 'react'
import { loaded, qs, addClass } from 'modules/modules'
import axios from 'axios'
import {  activateHoverCursor, deactivateHoverCursor } from 'modules/mouseStalker'
import { API_BASE_URL, } from 'config/Constants';

type Props = {
  catId: string
  handleChangeCategory: Function
  categoryTaxonomy: string
}

const CategoriesList = (props: Props) => {

  const [categories, setCategories] = useState([])

  useEffect(() => {
    const query = "/" + props.categoryTaxonomy;
    const uri = API_BASE_URL + query

    axios({
      method: 'get',
      url: uri,
      timeout: 10000
    })
      .then(function (res) {
        setCategories(res.data)
      })
      .catch(function (error) {
        addClass(qs(".c__categories_list.js__load_required"),"failed");
      })
      .finally(function () {
      });
  }, [])



  function showCategories(): any {
    let allButton = [<li key="all"><a className={"category_buttons" + (props.catId ? "" : " active")} onClick={() => props.handleChangeCategory("")} onMouseOver={activateHoverCursor} onMouseOut={deactivateHoverCursor}>All</a></li>]
    if (categories?.length >= 1) {
      loaded(".c__categories_list.js__load_required");
      return (
        allButton.concat(
          categories.map((category: any, index: number) => {
            return (
              <li key={category.id + "category"}><a className={"category_buttons" + (props.catId == category.id ? " active" : "")} onClick={() => props.handleChangeCategory(category.id)} onMouseOver={activateHoverCursor} onMouseOut={deactivateHoverCursor}>{category.name}</a></li>
            )
          })
        )
      )
    }
  }

  return (
    <>

      <ul className="c__categories_list js__load_required">
        {showCategories()}
      </ul>
    </>
  )
}

export default CategoriesList