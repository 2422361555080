import { useEffect } from 'react'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import { qs, qsAll, join, loaded, removeClass, addClass } from 'modules/modules'
import { addStalkerHoverEvent, removeStalkerHoverEvent } from "modules/mouseStalker"
import useBackground from "hooks/useBackground"
import { Article } from "components/models/works/Types";


type Props = {
  article: Article
}

export default function PrimaryArticle(props: Props) {

  dayjs.extend(utc);
  dayjs.extend(timezone);
  dayjs.tz.setDefault('Asia/Tokyo');

  const background = useBackground();

  useEffect(() => {
    background.activate()
    setTimeout(() => {
      loaded(".c__article_wrapper.js__load_required");
    }, 300);
  }, [props.article]);

  /*=======================================
    記事内リンクに対しての設定
  =======================================*/
  // aタグに対してホバーの反応と外部リンクで開く設定を追加
  useEffect(() => {
    const selector: string = ".c__article_body a"
    setTimeout(() => {
      addStalkerHoverEvent(selector);
      const anchors = qsAll(selector);
      for (const anchor of anchors) {
        anchor.setAttribute("target", "_blank");
        anchor.setAttribute("rel", "noopener noreferrer");
      }
    }, 500);
    return () => {
      removeStalkerHoverEvent(selector);
    }
  }, []);

  // iframeと重なったらマウスストーカーを非表示にする
  useEffect(() => {
    let stalker: HTMLElement | null = null;
    let iframes: Array<HTMLElement> = [];
    setTimeout(() => {
      stalker = qs("#mouse_stalker");
      iframes = qsAll("iframe");
      for (const elm of iframes) {
        elm.addEventListener('mouseover', function () {
          addClass(stalker, "disabled");
        });
        elm.addEventListener('mouseout', function () {
          removeClass(stalker, "disabled");
        });
      }
    }, 1000);
    return () => {
      for (const elm of iframes) {
        elm.removeEventListener('mouseover', function () {
          addClass(stalker, "disabled");
        });
        elm.removeEventListener('mouseout', function () {
          removeClass(stalker, "disabled");
        });
      }
    }
  }, []);

  return (
    <>
      <article className="c__article_wrapper js__load_required">

        <div className="article_head_info_first">
          <p className="article_category">{join(props.article.categories, "・")}</p>
          <p className="completed_at u__sp_only">{props.article.publishedDate}</p>
        </div>

        <div className="article_head_info_second">
          <h1 className="article_title">{props.article.title}</h1>
          <p className="completed_at u__sp_none">{props.article.publishedDate}</p>
        </div>

        <div className="c__article_body main_content" dangerouslySetInnerHTML={{ __html: props.article.content }} />

        <div className="sub_contents">
          {props.article.credit &&
            <div className="credit_wrapper">
              <h3 className="credit_wrapper_head">Credit</h3>
              <div className="c__article_body credit" dangerouslySetInnerHTML={{ __html: props.article.credit }} />
            </div>
          }
          {props.article.caption &&
            <div className="c__article_body caption" dangerouslySetInnerHTML={{ __html: props.article.caption }} />
          }
        </div>

      </article>
    </>
  );
}