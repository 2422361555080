import { Helmet } from "react-helmet-async"
import { useEffect } from 'react';
import { motion } from "framer-motion"
import { ISOvserver } from '../../../modules/modules'
import PrimaryHeading from 'components/headings/PrimaryHeading'
import PrimaryButton from "components/ui/PrimaryButton"
import { SITE_TITLE, SITE_URL_BASE } from 'config/Constants';

type Props = {
}
export default function Submitted(props: Props) {

  // アニメーション用
  useEffect(() => {
    let observer: IntersectionObserver;
    observer = ISOvserver();
    return () => {
      observer?.disconnect();
    };
  }, []);

  return (
    <>
      <Helmet>
        <title>{`Contact｜${SITE_TITLE}`}</title>
        <meta name="description" content={`お問い合わせ確認｜${SITE_TITLE}`} />
        <meta property="og:url" content={SITE_URL_BASE + "/contact/submitted"} />
        <meta property="og:title" content={`Contact｜${SITE_TITLE}`} />
      </Helmet>

      <motion.div
        initial={{ opacity: 0 }} // このページに遷移した直後
        animate={{ opacity: 1 }} // 最終的な状態
        exit={{ opacity: 0 }} // 離れる際にどうなるか
        transition={{ duration: 0.3 }} // 切り替えに要する時間
      >
        <main id="p__contact_submitted" className="">
          <PrimaryHeading letter="CONTACT" />
          <p className="submitted_description">
            この度はお問い合わせいただきありがとうございます。<br />
            担当者よりお返事させていただきますので、しばらくお待ちください。<br />
            <br />
            なお、2～3日経過してもお返事がない場合は、<br />
            入力いただいたメールアドレスに誤りがある可能性がございます。<br />
            その際は、お手数ですが再度お問い合わせいただきますよう、お願い申し上げます。
          </p>
          <PrimaryButton mode="link" path="/">Top</PrimaryButton >

        </main>
      </motion.div>

    </>
  );
}