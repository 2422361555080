import company from 'assets/img/headings/company.svg'
import contact from 'assets/img/headings/contact.svg'
import creator from 'assets/img/headings/creator.svg'
import news from 'assets/img/headings/news.svg'
import vurtual_estate from 'assets/img/headings/vurtual_estate.svg'
import works from 'assets/img/headings/works.svg'
import recommended from 'assets/img/headings/recommended.svg'
import terms from 'assets/img/headings/terms.svg'

type Props = {
  letter: string
  modifire?: string
}

export default function PrimaryHeading(props: Props) {

  // 渡された文字列に応じて画像を切り替える
  function getImgSrc(letter: string) {
    letter = letter.toLowerCase();
    switch (letter) {
      case "company":
        return company;
      case "contact":
        return contact;
      case "creator":
        return creator;
      case "news":
        return news;
      case "vurtual_estate":
        return vurtual_estate;
      case "works":
        return works;
      case "recommended":
        return recommended;
      case "terms":
        return terms;
    }
  }

  function getModifire () {
    return props.modifire ? props.modifire : ""
  }

  return (
    <h2 className={`c__head_primary ${getModifire()}`}>
      <img className="" src={getImgSrc(props.letter)} alt={props.letter} />
    </h2>
  );
}
