import axios from 'axios'
import { Helmet } from "react-helmet-async"
import { useEffect, useState } from 'react';
import { motion } from "framer-motion"
import PrimaryHeading from 'components/headings/PrimaryHeading'
import PrimaryButton from "components/ui/PrimaryButton"
import { useNavigate, useLocation } from "react-router-dom"
import { Values } from './Types'
import { ISOvserver, nl2br } from 'modules/modules'
import { SITE_TITLE, SITE_URL_BASE } from 'config/Constants';

type Props = {
}

export default function Confirm(props: Props) {

  const navigate = useNavigate(); // ページ遷移用
  const location = useLocation();

  const [values, setValues] = useState<Values>(location.state.values)

  // アニメーション用
  useEffect(() => {
    let observer: IntersectionObserver;
    observer = ISOvserver();
    return () => {
      observer?.disconnect();
    };
  }, []);


  // 戻るボタンが押されたら値をセットした上で戻る
  useEffect(() => {
    window.addEventListener('popstate', (e) => { handleBack(e) })
    return () => {
      window.removeEventListener('popstate', (e) => { handleBack(e) })
    }
  }, [])

  function handleBack(e: any) {
    e.preventDefault();
    navigate('/contact', { state: { values: values }, replace: true })
  }

  function handleSubmit(e: any) {

    e.preventDefault();

    axios({
      method: 'post',
      url: "/",
      timeout: 10000,
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      data: encode({ "form-name": "contact", "name": values.name, "organization": values.organization, "email": values.email, "category": values.category, "details": values.details })
    })
      .then(function (res) {
        navigate('/contact/submitted')
      })
      .catch(function (e) {
        alert("お問い合わせの送信に失敗しました。大変恐れ入りますが、しばらく時間を置いてもう一度お試しください。");
      })

    // フォームで送る値をエンコード
    function encode(data: any) {
      return Object.keys(data)
        .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
        .join("&");
    }
  };


  return (
    <>
      <Helmet>
        <title>{`Contact｜${SITE_TITLE}`}</title>
        <meta name="description" content={`お問い合わせ確認｜${SITE_TITLE}`} />
        <meta property="og:url" content={SITE_URL_BASE + "/contact/confirm"} />
        <meta property="og:title" content={`Contact｜${SITE_TITLE}`} />
      </Helmet>

      <motion.div
        initial={{ opacity: 0 }} // このページに遷移した直後
        animate={{ opacity: 1 }} // 最終的な状態
        exit={{ opacity: 0 }} // 離れる際にどうなるか
        transition={{ duration: 0.3 }} // 切り替えに要する時間
      >

        <main id="p__contact_confirm" className="">

          <PrimaryHeading letter="CONTACT" />

          <div className="contact_inner c__js_fade">

            <form name="contact" method="post">

              <div className="input_wrapper">
                <p className="label required">お名前／活動名</p>
                <p className="value">{values.name}</p>
              </div>

              <div className="input_wrapper">
                <p className="label">会社名／団体名</p>
                <p className="value">{values.organization}</p>
              </div>

              <div className="input_wrapper">
                <p className="label required">メールアドレス</p>
                <p className="value">{values.email}</p>
              </div>

              <div className="input_wrapper">
                <p className="label required">お問い合わせ種別</p>
                <p className="value radio">{values.category}</p>
              </div>

              <div className="input_wrapper">
                <p className="label required">お問い合わせ内容</p>
                <p className="value">{nl2br(values.details)}</p>
              </div>

              <div className="input_wrapper">
                <p className="label required">プライバシーポリシー</p>
                <p className="value radio"> 同意する</p>
              </div>

              <div className="button_wrapper">
                <PrimaryButton mode="button" type="submit" id="" onClick={(e: any) => handleBack(e)} variant="back">戻る</PrimaryButton >
                <PrimaryButton mode="button" type="submit" id="submit" onClick={(e: any) => handleSubmit(e)}>送信する</PrimaryButton >
              </div>

            </form>
          </div>

        </main>
      </motion.div>

    </>
  );
}