import { useEffect, useState,  } from 'react';
import { Link,  } from 'react-router-dom';
import { loaded, getParam, addClass, join, qs, removeClass, ISOvserve } from '../../../modules/modules'
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import axios from 'axios';
import noimage from 'assets/img/common/noimage.jpg'
import CategoriesList from '../category/CategoriesList'
import { activateHoverCursor, deactivateHoverCursor } from 'modules/mouseStalker'
import { API_BASE_URL } from 'config/Constants';


type Props = {
  limit: number
  pagenate: boolean
  category: boolean
}

export default function WorksList(props: Props) {

  dayjs.extend(utc);
  dayjs.extend(timezone);
  dayjs.tz.setDefault('Asia/Tokyo');

  // 記事セット
  const [articles, setArticleList] = useState([]);

  // ページング系
  const limit = props.limit;
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [catId, setCatId] = useState("");
  const [routeUniqueKey, setRouteUniqueKey] = useState(`${Math.random()}`);

  /*=======================================
    無限スクロール用
  =======================================*/
  useEffect(() => {
    let observer: IntersectionObserver = null;
    if (articles?.length >= 1 && currentPage < totalPages) {
      const targetSelector = ".c__articles_grid .anchor:last-child"; //記事の要素の一番最後
      const options = {
        rootMargin: "0px 0px -40% 0px",
      };
      observer = ISOvserve(
        targetSelector,
        options,
        () => {
          // フッターと交差したらページを増やす
          setCurrentPage(currentPage + 1);
        },
      )
    }
    return () => {
      observer?.disconnect();
    };
    // ISOvserveに渡した関数の中身のstate(currentPage)は自動で更新されないため、articlesを渡して明示的に更新をかける
    // 都度クリーンアップ処理が走るので問題ない
  }, [articles])



  // カテゴリ変更時は記事をリセット
  function handleChangeCategory(newCatId: string) {
    if (newCatId === catId) return

    const grid = qs(".c__articles_grid");
    addClass(grid, "disabled");
    // 消えるアニメーションが完了するまで待つ
    setTimeout(() => {
      setArticleList([])
      setCatId(newCatId)
      setCurrentPage(1)
    }, 200);
  }

  function composeApiUrl(): string {
    const previewModeQuery = getParam("previewMode") === "on" ? "&previewMode=on" : "";
    const categoryParam = catId === "" ? "" : `category=${catId}&`
    const query = `/works_list?${categoryParam}limit=${limit}&page=${currentPage}${previewModeQuery}`
    const uri = API_BASE_URL + query
    return uri
  }


  // 記事一覧を取得してセット
  useEffect(() => {

    const loader = qs(".c__loader");
    addClass(loader, "active");

    const uri = composeApiUrl();

    axios({
      method: 'get',
      url: uri,
      timeout: 10000
    })
      .then(function (res) {
        let tempArticles = articles;
        tempArticles = tempArticles.concat(res.data.contents);
        setArticleList(tempArticles);
        setTotalPages(parseInt(res.data.total_pages)); //総ページ数
      })
      .catch(function (error) {
        // 取得できませんでしたみたいなやつが表示される
        removeClass(qs(".c__article_notfound"), "disabled");
      })
      .finally(function () {
        loaded(".articles_grid_wrapper.js__load_required");
        removeClass(loader, "active");
      });

  }, [currentPage, catId, routeUniqueKey]);

  // サムネURLの設定
  function img_src(article: any) {
    if (article.img_src) {
      return article.img_src
    } else {
      return noimage
    }
  }

  function showArticles() {
    let postTitle;
    let categories: string[] = [];
    let postUrl;
    let previewQuery = getParam("previewMode") === "on" ? "?previewMode=on" : "";

    if (articles?.length >= 1) {
      return (
        <div className="c__articles_grid ">
          {
            articles.map((article: any, index: number) => {
              // タイトルの取得
              postTitle = String(article.title);
              // URLの取得
              postUrl = "/works/" + article.id + previewQuery;
              // カテゴリの取得
              categories = article.cat_names
              return (

                <Link
                  key={article.id}
                  to={postUrl}
                  className={`anchor delay${index % 12}`}
                  title={postTitle}
                  onMouseOver={activateHoverCursor}
                  onMouseOut={deactivateHoverCursor}
                  state={{ totalNum: totalPages * limit }}
                >
                  <div className="article_info">
                    <img src={img_src(article)} alt={postTitle} />
                    <div className="info">
                      <p className="categories">{join(categories, "・")}</p>
                      <p className="title">{postTitle}</p>
                    </div>
                  </div>
                </Link>
              )
            })}
        </div>
      )
    } else {
      return (
        <p className="c__article_notfound c__common_text disabled">
          データの取得に失敗しました。<br />
          時間をおいて再度お試しください。
        </p>
      )
    }
  }


  return (
    <>
      {props.category &&
        <CategoriesList categoryTaxonomy="categories" catId={catId} handleChangeCategory={handleChangeCategory} />}
      <div className="articles_grid_wrapper js__load_required" key={routeUniqueKey}>
        {showArticles()}
      </div>
      <div className="c__loader active">
        <span className="loader_dot first"></span>
        <span className="loader_dot second"></span>
        <span className="loader_dot third"></span>
      </div>
    </>
  );
}