
type Props = {
  children: React.ReactNode
  modifire?: string
}

export default function SecondaryHeading(props: Props) {

  function getModifire () {
    return props.modifire ? props.modifire : ""
  }

  return (
    <h3 className={`c__head_secondary ${getModifire()}`}>
      {props.children}
    </h3>
  );
}
